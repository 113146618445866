<template>
    <div class="templates">
        <div>
            <el-button size="mini" type="primary" @click="commitAudit">提交审核</el-button>
            <el-button size="mini" type="warning" @click="undoCodeAudit">撤销审核</el-button>
            <el-button size="mini" type="danger" @click="release">发布版本</el-button>
        </div>
        <common-table ref="templateTable" :apiKey="tableConfig.apiKey" :autoLoad="tableConfig.autoLoad"
            :search-params="searchForm" :columns="tableConfig.columns" :options="tableConfig.options" />
    </div>
</template>
<script>
export default {
    name: 'Templates',
    data() {
        return {
            tableConfig: {
                apiKey: 'fw-wx-mp-templateList',
                autoLoad: true,
                columns: [{
                    label: '创建时间',
                    prop: 'createTime'
                }, {
                    label: '开发名',
                    prop: 'sourceMiniprogram'
                }, {
                    label: '开发APPID',
                    prop: 'sourceMiniprogramAppid'
                }, {
                    label: '模版类型',
                    prop: 'templateType',
                    formatter: (row, column, cellVal) => {
                        return cellVal == '0' ? '普通' : '标准';
                    }
                }, {
                    label: '备注',
                    prop: 'userDesc'
                }, {
                    label: '版本',
                    prop: 'userVersion'
                }],
                options: [{
                    label: '体验',
                    powerId: '9999-30-10-10',
                    handler: (row) => {
                        this.commitTrial(row);
                    }
                }, {
                    label: '删除模版',
                    powerId: '9999-30-10-20',
                    handler: (row) => {
                        this.deleteTemplate(row);
                    }
                }]
            },
            searchForm: {}
        }
    },
    methods: {
        async commitAudit() {
            let loading = this.showLoading();
            try {
                await this.$http.doApi('fw-wx-mp-submitAudit');
                this.$message.success('提交成功');
            } catch (e) {
                console.log(e);
                this.$message.error('提交失败');
            } finally {
                loading.close();
            }
        },
        async undoCodeAudit() {
            let loading = this.showLoading();
            try {
                await this.$http.doApi('fw-wx-mp-undoCodeAudit');
                this.$message.success('撤销成功');
            } catch (e) {
                console.log(e);
                this.$message.error('撤销失败');
            } finally {
                loading.close();
            }
        },
        async release() {
            let loading = this.showLoading();
            try {
                await this.$http.doApi('fw-wx-mp-release');
                this.$message.success('提交成功');
            } catch (e) {
                console.log(e);
                this.$message.error('提交失败');
            } finally {
                loading.close();
            }
        },
        showLoading() {
            return this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        async commitTrial({ templateId, userVersion, userDesc }) {
            let loading = this.showLoading();
            try {
                await this.$http.doApi('fw-wx-mp-commitMp', {
                    templateId,
                    userVersion,
                    userDesc
                });
                this.$message.success('提交成功');
            } catch (e) {
                console.log(e);
                this.$message.error('提交失败');
            } finally {
                loading.close();
            }
        },
        async deleteTemplate({ templateId }) {
            let loading = this.showLoading();
            try {
                await this.$http.doApi('fw-wx-mp-deleteTemplate', {}, { "templateId": templateId });
                this.$message.success('删除成功');
                this.$refs.templateTable.refreshCurrent();
            } catch (e) {
                console.log(e);
                this.$message.error('删除失败');
            } finally {
                loading.close();
            }
        }
    }
}
</script>
<style lang="less"></style>